:root {
    --pricing-bg: rgb(50, 42, 98);
    --pricing-nav: rgb(61, 190, 201);
    --pricing-accent: rgb(239, 106, 57);
    --button-height: 80px;
    --button-margin: 2px;
    --small-button-height: 76px;
}

.nav-main{
    position: fixed;
    width: 100%;
    background-color: var(--pricing-nav);
    height: 120px;
    z-index:10000;
}

nav {
    position: fixed;
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.main-page {
    background-color: var(--pricing-bg);
    padding-top: 100px;
    min-width: 350px;
}

.main-body {
    margin: auto;
    width: 350px;
}

.page-title {
    padding-top: 35px;
}

.page-title p {
    color: var(--pricing-nav);
    text-align: left;
    font-size: 3em;
    padding-left: 0;
    padding-right: 0;
    font-weight: bolder;
}

.save {
    font-size: 1 em;
    margin-top: 35px;
    color: white;
}

.pricing-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    border-radius: 50px;
    height: var(--button-height);
    margin: auto;
    width: 75%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.pricing-button {
    color: black;
    font-size: 1.3em;
    margin: 2px;
    border-radius: 50px;
    cursor: pointer;
    margin: auto;
    line-height: var(--small-button-height);
    margin: var(--button-margin);
    height: var(--small-button-height);
}

.pricing-button-active {
    background-color: var(--pricing-accent);
    border-radius: 50px;
    color: white;
    font-size: 1.3em;
    margin: var(--button-margin);
    height: var(--small-button-height);
    margin-top: auto;
    margin-bottom: auto;
    line-height: var(--small-button-height)
}


/* Styles for landscape screen */
@media screen and (orientation:landscape){
    
    .desktop-support{
        display: flex;
        padding-left: 2.5%;
        margin: auto;
    }

    .main-page{
        padding-right: 1%;
    }

    .main-body {
        width: 100%;
    }

    .main-content{
        padding-right: 8%;
        padding-left: 0%;
        width: 450px;
        margin: auto;
    }

    .buttonsDS{
        padding-right: 2.5%;
        position: relative;
        top: -100px;
        width: 450px;
        margin: auto;
    }

    .page-title{
        margin-left: 14%;
    }
}
.nav-main{
    position: fixed;
    width: 100%;
    background-color: var(--nav1);
    height: 120px;
    z-index:10000;
}

nav {
    position: fixed;
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.nav-whip-logo-mono {
    display: grid;
    height: 120px;
}

.nav-whip-logo-mono img{
    margin: auto;
}

.nav-menu-icon img{
    width: 60px;   
}

.nav-btn-div {
    align-items: center;
}

.funnel-nav-main{
    position: fixed;
    width: 100%;
    height: 120px;
    z-index:10000;
}



/* other file  */




.top-nav {
    background-color: black;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: absolute;
    bottom: 5px;
    padding: 10px;
    border-radius: 10px;
}

.container {
    margin: 5px;
    justify-content: center;
    text-decoration: none;
}

.nav-main{
    position: fixed;
    width: 100%;
    background-color: var(--pricing-nav);
    height: 120px;
    z-index:10000;
}
  
nav {
    position: fixed;
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.nav-btn-div {
    align-items: center;
    border: none;
}

.nav-btn-div a{
    color: black;
    font-weight: bold;
    font-size: 1em;
}

.nav-btn-div .navbtn{
    border: none;
}
  
.nav-menu-icon img{
    width: 60px;  
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%); 
}
  
.nav-btn-div {
    align-items: center;
    border: none;
    color: white;
}
  
.nav-btn-div a{
    color: var(--pricing-bg);
    font-weight: bold;
    font-size: 1em;
}
  
.navbtn {
    text-decoration: none;
    border-radius: 50px;
    background-color: white;
    color: black;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    border: none;
}
  
.navbtn a {
    text-decoration: none;
}

.menu-container{
    height: 100vh;
    width: 90%;
    background-color: rgb(244, 170, 108);
    color: white;
    position: relative;
    z-index: 1000;
    animation: .5s alternate slidein;
    margin-left: 10%;
}

.menu-container a:visited {
    color: white;
}

@keyframes slidein {
    from {
      margin-left: 100%;
    }
  
    to {
      margin-left: 10%;
    }
}

.inner-menu{
    padding-top: 50px;
    width: 80%;
    height: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-rows: 70px;
}

.nav-menu-item{
    font-size: 1.5em;
    font-weight: 500;
    padding-bottom: 15px;
    border-bottom: solid 1px;
    background-color: rgb(244, 170, 108);
    border-color: rgb(240, 193, 154);
    height: 30px;
}

.nav-menu-item a{
    text-decoration: none;
}

.sub{
    margin-left: 20px;
}

.x{
    position: absolute;
    right: 25px;
    top: 25px;
    font-weight: bolder;
    font-size: 1.5em;
}

.member {
    margin: auto;
}


.logout{
    margin: auto;
    width: 25%;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    transition: .2s ease;
    color: black;
}

.logout:hover{
    background-color: black;
    color: white;
}

.follow{
    margin: auto;
}

.menu-grid-container {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-auto-rows: 50px;
}

.menu-grid-container div{
    position: relative;
    width: 50px;
}

.invert{
    filter: invert();
}

.xout{
    position: absolute;
    top: 30px;
    right: 9px;
}


/* Styles for landscape screen */
@media screen and (orientation:landscape){

    .nav-whip-logo{
        position: fixed;
        left: 2%;
    }

    .nav-menu-icon{
        right: 2%;
        position: fixed;
    }

    .nav-btn-div .navbtn{
        width: 450px;
    }

    .menu-container{
        position: absolute;
        width: 400px;
        right: 0px;
    }

    @keyframes slidein {
        from {
          right: -400px;
        }
      
        to {
          right: 0px;
        }
    }

    .xout{
        right: 37px;
    }
}
:root {
    --checkout-button-height: 70px;
    --checkout-button-margin: 3px;
    --checkout-small-button-height: 64px;
}

.checkoutlogosvg {
    padding-top: 30px;
    margin: auto;
    filter: invert(12%) sepia(96%) saturate(5653%) hue-rotate(2deg) brightness(101%) contrast(118%); 
    width: 150px; 
    margin-bottom: 20px;
}

.checkout {
    width: 100%;
}

.checkout-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    border-radius: 50px;
    height: var(--checkout-button-height);
    margin: auto;
    width: 50%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
    border: 2px solid;
    border-color: black;
}

.checkout-button {
    color: black;
    font-size: 1.3em;
    margin: 2px;
    border-radius: 50px;
    cursor: pointer;
    margin: auto;
    margin: var(--checkout-button-margin);
    height: var(--checkout-small-button-height);
    background-color: white;
    margin: auto;
    line-height: var(--checkout-small-button-height);
}

.checkout-button p{
    width: 100%;
    height: 100%;
}

.checkout-button-active {
    background-color: black;
    border-radius: 50px;
    color: white;
    font-size: 1.3em;
    margin: var(--checkout-button-margin);
    height: var(--checkout-small-button-height);
    margin: auto;
    width: 94%;
    line-height: var(--checkout-small-button-height);
}

.checkout-button-active p{
    color: white;
    text-align: center;
}

p{
    color: black;
}

.checkout-title{
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.checkout-subtitle{
    text-align: center;
    color: gray;
}

.options{
    margin: auto;
    width: 100%;
}

/********************* Cards **********************/
/********************* Cards **********************/
/********************* Cards **********************/
/********************* Cards **********************/
/********************* Cards **********************/

.checkout-cards-container{
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
}

.chart-container{
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}

.chart-container .grid-container{
    border-color: black;
    border: 3px solid;
}

.checkout-card {
    border-radius: 25px;
    margin-top: 30px;
    padding-bottom: 15px;
    border: 3px solid;
    border-color: lightgray;
    position: relative;
}

.checkout-card-header {
    background-color: lightgray;
    color: black;
    height: 150px;
    border-radius: 20px;
}

.checkout-card-header-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.checkout-card-header-section span{
    font-weight: normal;
}

.checkout-card-header-section p{
    text-align: left;
    margin-left: 20px;
    font-size: 1.5em;
    margin-top: 10px;
}

.checkout-card-content {
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    margin-top: 15px;
}

.checkout-description {
    width: 85%;
    margin: auto;
    margin-top: -70px;
    color: black;
    font-size: 1em;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}

.checkout-label {
    background-color: var(--pricing-accent);
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    color: white;
    width: 140px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -20px;
}

.checkout-get {
    height: 60px;
    border-radius: 50px;
    background-color: white;
    border-color: var(--pricing-nav);
    color: var(--pricing-nav);
    align-items: center;
    font-size: 1.3em;
    margin-bottom: 10px;
    width: 70%;
    cursor: pointer;
}

h2 {
    color: white;
    text-align: left;
    margin-left: 7%;
}

.circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: white;
    border: 1px solid;
    border-color: gray;
}

.included {
    font-size: 1em;
    text-align: center;
    font-weight: bold;
}

/* Styles for landscape screen */
@media screen and (orientation:landscape){
    .checkout {
        display: flex;
        width: 100%;
        margin: auto;
    }

    .checkout-left{
        width: 30%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .chart-container{
        width: 50%;
    }
}
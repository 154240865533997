h2, h3 {
    padding: 20px;
}

.banner {
    display: inline-block;
    width: 90%;
    padding-top: 5%;
    position: relative;
    height: 25vh;
}

.banner img {
    width: 100%;
    border-radius: 25px;
    height: 90%;
    object-fit: cover;
}

.user-title {
    display: block;
    color: white;
    font-weight: 400;
}

.user-sub-title {
    display: block;
    color: white;
    font-weight: 300;
}

.userbackground {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgb(245, 160, 170), rgb(227, 51, 110));
    max-width: 100%;
    overflow-x: hidden;
}

.userbackground .avatar-div {
    display: flex;
    width: 100%;
    text-align: left;
    position: relative;
    left: 6%;
    margin-top: -30px;
}

.user-avatar {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    left: 10%;
    top: -100%;
    border: 4px solid rgb(245, 160, 170);
}

.user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-text{
    text-align: left;
    font-size: .8em;
    vertical-align: bottom;
    padding-left: 1%;
    padding-top: 25px;
}

.user-socials-container {
    padding-top: 1%;
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    position: relative;
}

.user-socials-container div{
    margin: auto;
    width: 80%;
}

.user-socials-container div img{
    transition: .1s ease;
}

.user-socials-container div img:hover{
    filter: none;
    transform: rotate(15deg);
    transition: .3s ease;
}

.user-button{
    width: 90%;
    color: black;
    background: white;
    border-radius: 30px;
    margin: auto;
    line-height: 60px;
    font-size: x-large;
    margin: 10px auto;
    transition: .3s ease;
}

.user-button:hover{
    color: white;
    background: black;
    transform: rotate(5deg);
}


.user-page-content{
    width: 100%;
}

/* Styles for landscape screen */
@media screen and (orientation:landscape){

    .user-page-content{
        width: 40%;
        margin: auto;
    }

    .banner {
        padding-top: 1%;
        height: 40vh;
    }

}
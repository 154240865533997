@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* variables */
:root {
  --heading-color: rgb(0, 18, 180);
  --text-color: #999;
  --primary-color: #d31f1f;
  --highlight-color: #d13267;
}

/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

ul {
  list-style-type: none;
}

.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 15px;
  border-width: 3px;
  color: #d31f1f;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid #d31f1f;
  border: 1px solid var(--primary-color);
  margin: 2px;
}

.btn:hover {
  color: #fff;
  background-color: #d31f1f;
  background-color: var(--primary-color);
}

h1{
  border: #d31f1f;
  border: var(--primary-color);
}

.big-logo {
  max-width: 90%;
  margin: auto;
}

.big-logo-div {
  padding-top: 50px;
  display: grid;
}

.backgroundgradient {
  height: 100vh;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(197, 10, 10));
}

.title {
  margin: 0;
  font-size: 3em;
  color: white;
  letter-spacing: 3px;
  text-shadow: -1px 1px 0px #d31f1f,
                1px 1px 0 #d31f1f,
                1px -1px 0 #d31f1f,
                -1px -1px 0 #d31f1f;
  text-shadow: -1px 1px 0px var(--primary-color),
                1px 1px 0 var(--primary-color),
                1px -1px 0 var(--primary-color),
                -1px -1px 0 var(--primary-color);
}

/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
label {
  display: block;
  margin: auto;
  max-width: 90%;
}
label span {
  display: block;
  color: white;
  margin-bottom: 6px;
  text-align: left;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

textarea {
  min-height: 160px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin-top: 20px;
}

.message {
  color: green;
  background: greenyellow;
  border: 1px solid green;
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  margin: 20px auto;
}

.clickableLink{
  text-decoration: underline;
}

.clickableLink:hover {
  color: gray;
}

a {
  color: white;
}

a:visited {
  color: lightcoral;
}

.logosvg {
  -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%);
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%);
  width: 160px;
  pointer-events: none;
}

/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
@media screen and (orientation:landscape){
  .big-logo {
      max-width: 50%;
      margin: auto;
  }

  label {
    max-width: 30%;
  }

  .title {
    font-size: 6em;
  }

  .btn {
    width: 40%;
  }
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}




.container {
    height: 100px;
    display: grid;
    grid-template-columns: .8fr 1.6fr;
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 15px;
    justify-content: center;
    overflow-x: hidden;
}

.l {
    background: rgb(255, 141, 170);
    border-radius: 50%;
    width: 100px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    position: fixed;
}

.r {
    background: rgb(32, 7, 255);
    border-radius: 50px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    position: fixed;
}

.bottomnav-btn {
    text-decoration: none;
    border-radius: 50px;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 70px;
    line-height: 70px;
    padding: 5px;
}

a.navbtn:hover {
    font-size: 1.6em;
}

.l:hover {
    background-color: rgb(219, 41, 255);
}

.r:hover {
    background-color: rgb(102, 186, 255);
}
h2, h3 {
    padding: 20px;
}

.banner {
    display: inline-block;
    width: 90%;
    padding-top: 5%;
    position: relative;
    height: 25vh;
}

.banner img {
    width: 100%;
    border-radius: 25px;
    height: 90%;
    object-fit: cover;
}

.user-title {
    display: block;
    color: white;
    font-weight: 400;
}

.user-sub-title {
    display: block;
    color: white;
    font-weight: 300;
}

.userbackground {
    height: 100vh;
    width: 100%;
    background-image: linear-gradient(rgb(245, 160, 170), rgb(227, 51, 110));
    max-width: 100%;
    overflow-x: hidden;
}

.userbackground .avatar-div {
    display: flex;
    width: 100%;
    text-align: left;
    position: relative;
    left: 6%;
    margin-top: -30px;
}

.user-avatar {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    left: 10%;
    top: -100%;
    border: 4px solid rgb(245, 160, 170);
}

.user-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-text{
    text-align: left;
    font-size: .8em;
    vertical-align: bottom;
    padding-left: 1%;
    padding-top: 25px;
}

.user-socials-container {
    padding-top: 1%;
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    position: relative;
}

.user-socials-container div{
    margin: auto;
    width: 80%;
}

.user-socials-container div img{
    transition: .1s ease;
}

.user-socials-container div img:hover{
    -webkit-filter: none;
            filter: none;
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
    transition: .3s ease;
}

.user-button{
    width: 90%;
    color: black;
    background: white;
    border-radius: 30px;
    margin: auto;
    line-height: 60px;
    font-size: x-large;
    margin: 10px auto;
    transition: .3s ease;
}

.user-button:hover{
    color: white;
    background: black;
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
}


.user-page-content{
    width: 100%;
}

/* Styles for landscape screen */
@media screen and (orientation:landscape){

    .user-page-content{
        width: 40%;
        margin: auto;
    }

    .banner {
        padding-top: 1%;
        height: 40vh;
    }

}
* {
    margin: 0;
    padding: 0;
}

.the-form {
    padding: 20px;
}

.the-form label{
    padding: 10px;
}

.field {
    border-color: var(--primary-color);
    border-width: .1em;
}

span {
    font-weight: bold;
}

.filebox{
    background-color: white;
}

/* Bottom links */
.links{
    max-width: 100%;
    padding-top: 60px;
}

.btn {
    margin-top: 25px;
    width: 75%;
    text-decoration: none;
    border-radius: 50px;
    background-color: white;
    color: var(--primary-color);
    font-size: 1.5em;
    font-weight: bolder;
    cursor: pointer;
    height: 80px;
    display: inline;
    border-style: none;
}

.the-form input {
    height: 70px;
    border-radius: 10px;
}

.up-top {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}

.up-top-text {
    color: white;
    font-size: 3em;
    letter-spacing: -5px;
    padding-right: 10px;
    font-weight: bolder;
}

.login-text {
    color: white;
}


.login-logo {
    margin: auto;
    pointer-events: all;
}
  
.login-logo-div {
    display: flex;
}

  
/* Styles for landscape screen */
@media screen and (orientation:landscape){
    .big-logo {
        max-width: 50%;
        margin: auto;
    }
  
    label {
      max-width: 40%;
    }
  
    .title {
      font-size: 6em;
    }
  
    .btn {
      width: 40%;
    }

    .links{
        padding-top: 10px;
    }
  }
.names{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    max-width: 95%;
}

.name {
    padding: 10px;
    padding-bottom: 0px;
}

.title{
    font-size: 1.3em;
    padding: 0px;
}

p {
    color: gray;
    font-size: .7em;
    text-align: left;
}

.signuplogosvg {
    padding-top: 10px;
    margin: auto;
    -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%);
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%); 
    width: 250px;  
}

.terms {
    color: gray;
    padding-top: 10px;
    font-size: .5em;
}

.last-link {
    margin: 20px 0px;
}

.signupgradient {
    height: 100vh;
    background-image: linear-gradient(rgb(0, 0, 0), rgb(197, 10, 10));
}

.signupgradient label{
    padding: 5px;
}

.main-body p{
    color: white;
}
.nav-main{
    position: fixed;
    width: 100%;
    background-color: var(--nav1);
    height: 120px;
    z-index:10000;
}

nav {
    position: fixed;
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.nav-whip-logo-mono {
    display: grid;
    height: 120px;
}

.nav-whip-logo-mono img{
    margin: auto;
}

.nav-menu-icon img{
    width: 60px;   
}

.nav-btn-div {
    align-items: center;
}

.funnel-nav-main{
    position: fixed;
    width: 100%;
    height: 120px;
    z-index:10000;
}



/* other file  */




.top-nav {
    background-color: black;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    position: absolute;
    bottom: 5px;
    padding: 10px;
    border-radius: 10px;
}

.container {
    margin: 5px;
    justify-content: center;
    text-decoration: none;
}

.nav-main{
    position: fixed;
    width: 100%;
    background-color: var(--pricing-nav);
    height: 120px;
    z-index:10000;
}
  
nav {
    position: fixed;
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.nav-btn-div {
    align-items: center;
    border: none;
}

.nav-btn-div a{
    color: black;
    font-weight: bold;
    font-size: 1em;
}

.nav-btn-div .navbtn{
    border: none;
}
  
.nav-menu-icon img{
    width: 60px;  
    -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%);  
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%); 
}
  
.nav-btn-div {
    align-items: center;
    border: none;
    color: white;
}
  
.nav-btn-div a{
    color: var(--pricing-bg);
    font-weight: bold;
    font-size: 1em;
}
  
.navbtn {
    text-decoration: none;
    border-radius: 50px;
    background-color: white;
    color: black;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    border: none;
}
  
.navbtn a {
    text-decoration: none;
}

.menu-container{
    height: 100vh;
    width: 90%;
    background-color: rgb(244, 170, 108);
    color: white;
    position: relative;
    z-index: 1000;
    -webkit-animation: .5s alternate slidein;
            animation: .5s alternate slidein;
    margin-left: 10%;
}

.menu-container a:visited {
    color: white;
}

@-webkit-keyframes slidein {
    from {
      margin-left: 100%;
    }
  
    to {
      margin-left: 10%;
    }
}

@keyframes slidein {
    from {
      margin-left: 100%;
    }
  
    to {
      margin-left: 10%;
    }
}

.inner-menu{
    padding-top: 50px;
    width: 80%;
    height: 100%;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-rows: 70px;
}

.nav-menu-item{
    font-size: 1.5em;
    font-weight: 500;
    padding-bottom: 15px;
    border-bottom: solid 1px;
    background-color: rgb(244, 170, 108);
    border-color: rgb(240, 193, 154);
    height: 30px;
}

.nav-menu-item a{
    text-decoration: none;
}

.sub{
    margin-left: 20px;
}

.x{
    position: absolute;
    right: 25px;
    top: 25px;
    font-weight: bolder;
    font-size: 1.5em;
}

.member {
    margin: auto;
}


.logout{
    margin: auto;
    width: 25%;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    transition: .2s ease;
    color: black;
}

.logout:hover{
    background-color: black;
    color: white;
}

.follow{
    margin: auto;
}

.menu-grid-container {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-auto-rows: 50px;
}

.menu-grid-container div{
    position: relative;
    width: 50px;
}

.invert{
    -webkit-filter: invert();
            filter: invert();
}

.xout{
    position: absolute;
    top: 30px;
    right: 9px;
}


/* Styles for landscape screen */
@media screen and (orientation:landscape){

    .nav-whip-logo{
        position: fixed;
        left: 2%;
    }

    .nav-menu-icon{
        right: 2%;
        position: fixed;
    }

    .nav-btn-div .navbtn{
        width: 450px;
    }

    .menu-container{
        position: absolute;
        width: 400px;
        right: 0px;
    }

    @-webkit-keyframes slidein {
        from {
          right: -400px;
        }
      
        to {
          right: 0px;
        }
    }

    @keyframes slidein {
        from {
          right: -400px;
        }
      
        to {
          right: 0px;
        }
    }

    .xout{
        right: 37px;
    }
}
:root {
    --bg1: rgb(49, 42, 94);
    --nav1: rgb(209, 97, 59);
    --accent1: rgb(246, 237, 146);

    --nav2: rgb(219, 223, 92);
    --bg2: rgb(66, 128, 117);
    --accent2: rgb(129, 35, 32);

    --bg3: rgb(231, 189, 69);
    --nav3: rgb(48, 107, 61);
    --accent3: rgb(0, 0, 0);

    --bg4: rgb(133, 26, 18);
    --nav4: rgb(246, 237, 146);
    --accent4: rgb(49, 42, 94);
    --box-color: rgb(154, 59, 55);
}

/* PAGE 1 */
/* PAGE 1 */
/* PAGE 1 */
/* PAGE 1 */

.page1 {
    padding-top: 120px;
    background-color: rgb(49, 42, 94);
    background-color: var(--bg1);
    padding-bottom: 200px;
}

.page1 p {
    color: rgb(209, 97, 59);
    color: var(--nav1);
}

.page1-title-text {
    color: rgb(209, 97, 59);
    color: var(--nav1);
    font-size: 2em;
    font-weight: 800;
    text-align: left;
}

.page1-title {
    padding-top: 25px;
    padding-bottom: 25px;
}

.page1-description {
    text-align: left;
    font-size: 1.3em;
}

.page1-name-box {
    border-radius: 10px;
    height: 60px;
}

.page1-label {
    padding-top: 50px;
    margin-left: 35px;
    margin-bottom: 0px;
}

.page1-cta{
    margin-top: 15px;
    width: 300px;
    text-decoration: none;
    border-radius: 50px;
    background-color: rgb(246, 237, 146);
    background-color: var(--accent1);
    color: rgb(49, 42, 94);
    color: var(--bg1);
    font-size: 1.5em;
    font-weight: bolder;
    cursor: pointer;
    height: 80px;
    display: inline;
    border-style: none;
}

.page1-form {
    width: 80%;
}

/* PAGE 2 */
/* PAGE 2 */
/* PAGE 2 */
/* PAGE 2 */

.page2 {
    background-color: rgb(66, 128, 117);
    background-color: var(--bg2);
    padding-bottom: 100px;
}

.page2 p {
    color: rgb(219, 223, 92);
    color: var(--nav2);
}

.page2-title-text {
    color: rgb(129, 35, 32);
    color: var(--accent2);
    font-size: 2em;
    font-weight: 800;
    text-align: left;
}

.page2-title {
    padding-top: 50px;
}

.page2-description {
    padding-top: 25px;
    text-align: left;
    font-size: 1.3em;
}

.page2-cta{
    text-decoration: none;
    border-radius: 50px;
    background-color: rgb(129, 35, 32);
    background-color: var(--accent2);
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    margin-top: 150px;
    width: 300px;
    margin-bottom: 200px;
    border-style: none;
}

/* PAGE 3 */
/* PAGE 3 */
/* PAGE 3 */
/* PAGE 3 */

.page3 {
    background-color: rgb(231, 189, 69);
    background-color: var(--bg3);
    padding-bottom: 100px;
}

.page3 p {
    color: rgb(48, 107, 61);
    color: var(--nav3);
}

.page3-title-text {
    font-size: 2em;
    font-weight: 800;
    text-align: left;
}

.page3-title {
    padding-top: 50px;
}

.page3-description {
    padding-top: 25px;
    text-align: left;
    font-size: 1em;
}

.page3-cta{
    text-decoration: none;
    border-radius: 50px;
    background-color: rgb(0, 0, 0);
    background-color: var(--accent3);
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    margin-top: 50px;
    width: 300px;
    border-style: none;
}

/* PAGE 4 */
/* PAGE 4 */
/* PAGE 4 */
/* PAGE 4 */

.page4 {
    background-color: rgb(133, 26, 18);
    background-color: var(--bg4);
    padding-bottom: 100px;
}

.page4-title-text {
    font-size: 2em;
    font-weight: 800;
    text-align: left;
    color: rgb(246, 237, 146);
    color: var(--nav4)
}

.page4-title {
    padding-top: 50px;
    margin-bottom: 50px;
}

.page4-box-title {
    color: rgb(246, 237, 146);
    color: var(--nav4);
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5em;
}

.page4-cta{
    text-decoration: none;
    border-radius: 50px;
    background-color: rgb(49, 42, 94);
    background-color: var(--accent4);
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    margin-top: 50px;
    width: 300px;
    border-style: none;
}

.page4-box {
    background-color: rgb(154, 59, 55);
    background-color: var(--box-color);
    margin: auto;
    width: 90%;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    border-style: none;
    padding: 10px;
    border-radius: 25px;
}

.page4-box-info{
    color: white;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.3em;
    -webkit-animation: .4s slideInFromTop;
            animation: .4s slideInFromTop;
}

@-webkit-keyframes slideInFromTop {
    0% {
        -webkit-transform: translateY(-30%);
                transform: translateY(-30%);
        opacity: 0%;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    0% {
        -webkit-transform: translateY(-30%);
                transform: translateY(-30%);
        opacity: 0%;
    }
    100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
}

.page4 span {
    margin-left: 22px;
}

.page4-box img {
    z-index: 100;
    position: absolute;
    right: 50px;
    margin-top: -40px;
    z-index: 100;
}

.upside-down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

/* Styles for landscape screen */
@media screen and (orientation:landscape){
    .page4-box img {
        right: 120px;
    }

    .page1-form {
        padding-left: 5%;
    }

    .page1-content, .page2-content, .page3-content, .page4-content{
        width: 40%;
        margin: auto;
    }

    .page4-box img {
        right: 34%;
    }

}
p {
    padding-left: 5%;
    padding-right: 5%;
    color: white;
}

.signup-link {
    padding-top: 10px;
}
.main-content{
    width: 100%;
}

.grid-labels {
    display: grid;
    grid-template-columns: 10% 40% 17% 16% 17%;
    grid-auto-rows: 50px;
    border-radius: 10px;
    grid-row-gap: 1px;
    color: white;
    font-size: .85em;
}

.grid-container {
    display: grid;
    grid-template-columns: 10% 40% 17% 16% 17%;
    grid-auto-rows: 50px;
    background-color: white;
    border-radius: 10px;
    grid-row-gap: 1px;
}

.starter{
    background-color: rgb(61, 190, 201);
    position: relative;
    border-radius: 10px 10px 0 0;
}

.other-label{
    position: relative;
}

.grid-label{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 50px;
}

.grid-item{
    border-bottom: 1px solid #bbbbbb;
    height: 50px;
    position: relative;
}

.last-grid-item{
    height: 50px;
    position: relative;
}

.infinity{
    height: 25%;
}

.grid-item img{
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.last-grid-item img{
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.arrow {
    -webkit-filter: invert(52%) sepia(9%) saturate(34%) hue-rotate(318deg) brightness(87%) contrast(89%);
            filter: invert(52%) sepia(9%) saturate(34%) hue-rotate(318deg) brightness(87%) contrast(89%);
    height: 70%;
}

.notIncluded {
    -webkit-filter: invert(52%) sepia(9%) saturate(34%) hue-rotate(318deg) brightness(87%) contrast(89%);
            filter: invert(52%) sepia(9%) saturate(34%) hue-rotate(318deg) brightness(87%) contrast(89%);
    height: 30%;
}

.included {
    -webkit-filter: invert(63%) sepia(69%) saturate(2945%) hue-rotate(87deg) brightness(91%) contrast(95%);
            filter: invert(63%) sepia(69%) saturate(2945%) hue-rotate(87deg) brightness(91%) contrast(95%);
    height: 90%;
    width: 70%;
}

.grid-item-1{
    
}

.grid-item-2{
    background-color: white;
    text-align: left;
}

.chart-label{
    font-size: .8em;
    text-align: left;
    line-height: 50px;
    font-weight: 450;
}

.chart-text{
    font-size: .8em;
    text-align: center;
    line-height: 50px;
    font-size: larger;
    font-weight: 500;
}

.grid-item-4{
    background-color: rgb(227, 251, 252);
}

.card {
    border-radius: 10px;
    margin-top: 15px;
    padding-bottom: 15px;
}

.card-header {
    background-color: var(--pricing-nav);
    color: white;
    height: 150px;
    border-radius: 20px;
}

.card-header-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card-header-section p{
    color: white;
}

.card-header-section span{
    font-weight: normal;
}

.card-header-section p{
    text-align: left;
    margin-left: 20px;
    font-size: 1.5em;
    margin-top: 10px;
}

.card-content{
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    margin-top: 15px;
}

.card-content p{
    color: black;
}

.description {
    width: 85%;
    margin: auto;
    margin-top: -70px;
    color: black;
    font-size: 1em;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}

.label {
    background-color: var(--pricing-accent);
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    color: white;
    width: 150px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -25px;
}

.get {
    height: 60px;
    border-radius: 50px;
    background-color: white;
    border-color: var(--pricing-nav);
    color: var(--pricing-nav);
    align-items: center;
    font-size: 1.3em;
    margin-bottom: 10px;
    width: 70%;
    cursor: pointer;
}

h2 {
    color: white;
    text-align: left;
    margin-left: 7%;
}

.get a{
    color: var(--pricing-nav);
    text-decoration: none;
    font-weight: bold;
}


/* Styles for landscape screen */
@media screen and (orientation:landscape){

}
:root {
    --pricing-bg: rgb(50, 42, 98);
    --pricing-nav: rgb(61, 190, 201);
    --pricing-accent: rgb(239, 106, 57);
    --button-height: 80px;
    --button-margin: 2px;
    --small-button-height: 76px;
}

.nav-main{
    position: fixed;
    width: 100%;
    background-color: rgb(61, 190, 201);
    background-color: var(--pricing-nav);
    height: 120px;
    z-index:10000;
}

nav {
    position: fixed;
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;
}

.main-page {
    background-color: rgb(50, 42, 98);
    background-color: var(--pricing-bg);
    padding-top: 100px;
    min-width: 350px;
}

.main-body {
    margin: auto;
    width: 350px;
}

.page-title {
    padding-top: 35px;
}

.page-title p {
    color: rgb(61, 190, 201);
    color: var(--pricing-nav);
    text-align: left;
    font-size: 3em;
    padding-left: 0;
    padding-right: 0;
    font-weight: bolder;
}

.save {
    font-size: 1 em;
    margin-top: 35px;
    color: white;
}

.pricing-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    border-radius: 50px;
    height: 80px;
    height: var(--button-height);
    margin: auto;
    width: 75%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.pricing-button {
    color: black;
    font-size: 1.3em;
    margin: 2px;
    border-radius: 50px;
    cursor: pointer;
    margin: auto;
    line-height: 76px;
    line-height: var(--small-button-height);
    margin: 2px;
    margin: var(--button-margin);
    height: 76px;
    height: var(--small-button-height);
}

.pricing-button-active {
    background-color: rgb(239, 106, 57);
    background-color: var(--pricing-accent);
    border-radius: 50px;
    color: white;
    font-size: 1.3em;
    margin: 2px;
    margin: var(--button-margin);
    height: 76px;
    height: var(--small-button-height);
    margin-top: auto;
    margin-bottom: auto;
    line-height: 76px;
    line-height: var(--small-button-height)
}


/* Styles for landscape screen */
@media screen and (orientation:landscape){
    
    .desktop-support{
        display: flex;
        padding-left: 2.5%;
        margin: auto;
    }

    .main-page{
        padding-right: 1%;
    }

    .main-body {
        width: 100%;
    }

    .main-content{
        padding-right: 8%;
        padding-left: 0%;
        width: 450px;
        margin: auto;
    }

    .buttonsDS{
        padding-right: 2.5%;
        position: relative;
        top: -100px;
        width: 450px;
        margin: auto;
    }

    .page-title{
        margin-left: 14%;
    }
}
:root {
    --checkout-button-height: 70px;
    --checkout-button-margin: 3px;
    --checkout-small-button-height: 64px;
}

.checkoutlogosvg {
    padding-top: 30px;
    margin: auto;
    -webkit-filter: invert(12%) sepia(96%) saturate(5653%) hue-rotate(2deg) brightness(101%) contrast(118%);
            filter: invert(12%) sepia(96%) saturate(5653%) hue-rotate(2deg) brightness(101%) contrast(118%); 
    width: 150px; 
    margin-bottom: 20px;
}

.checkout {
    width: 100%;
}

.checkout-button-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: white;
    border-radius: 50px;
    height: 70px;
    height: var(--checkout-button-height);
    margin: auto;
    width: 50%;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 50px;
    border: 2px solid;
    border-color: black;
}

.checkout-button {
    color: black;
    font-size: 1.3em;
    margin: 2px;
    border-radius: 50px;
    cursor: pointer;
    margin: auto;
    margin: 3px;
    margin: var(--checkout-button-margin);
    height: 64px;
    height: var(--checkout-small-button-height);
    background-color: white;
    margin: auto;
    line-height: 64px;
    line-height: var(--checkout-small-button-height);
}

.checkout-button p{
    width: 100%;
    height: 100%;
}

.checkout-button-active {
    background-color: black;
    border-radius: 50px;
    color: white;
    font-size: 1.3em;
    margin: 3px;
    margin: var(--checkout-button-margin);
    height: 64px;
    height: var(--checkout-small-button-height);
    margin: auto;
    width: 94%;
    line-height: 64px;
    line-height: var(--checkout-small-button-height);
}

.checkout-button-active p{
    color: white;
    text-align: center;
}

p{
    color: black;
}

.checkout-title{
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
}

.checkout-subtitle{
    text-align: center;
    color: gray;
}

.options{
    margin: auto;
    width: 100%;
}

/********************* Cards **********************/
/********************* Cards **********************/
/********************* Cards **********************/
/********************* Cards **********************/
/********************* Cards **********************/

.checkout-cards-container{
    width: 90%;
    margin: auto;
    margin-bottom: 50px;
}

.chart-container{
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
}

.chart-container .grid-container{
    border-color: black;
    border: 3px solid;
}

.checkout-card {
    border-radius: 25px;
    margin-top: 30px;
    padding-bottom: 15px;
    border: 3px solid;
    border-color: lightgray;
    position: relative;
}

.checkout-card-header {
    background-color: lightgray;
    color: black;
    height: 150px;
    border-radius: 20px;
}

.checkout-card-header-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.checkout-card-header-section span{
    font-weight: normal;
}

.checkout-card-header-section p{
    text-align: left;
    margin-left: 20px;
    font-size: 1.5em;
    margin-top: 10px;
}

.checkout-card-content {
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    margin-top: 15px;
}

.checkout-description {
    width: 85%;
    margin: auto;
    margin-top: -70px;
    color: black;
    font-size: 1em;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}

.checkout-label {
    background-color: var(--pricing-accent);
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    color: white;
    width: 140px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -20px;
}

.checkout-get {
    height: 60px;
    border-radius: 50px;
    background-color: white;
    border-color: var(--pricing-nav);
    color: var(--pricing-nav);
    align-items: center;
    font-size: 1.3em;
    margin-bottom: 10px;
    width: 70%;
    cursor: pointer;
}

h2 {
    color: white;
    text-align: left;
    margin-left: 7%;
}

.circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    border-radius: 13px;
    background-color: white;
    border: 1px solid;
    border-color: gray;
}

.included {
    font-size: 1em;
    text-align: center;
    font-weight: bold;
}

/* Styles for landscape screen */
@media screen and (orientation:landscape){
    .checkout {
        display: flex;
        width: 100%;
        margin: auto;
    }

    .checkout-left{
        width: 30%;
        margin-left: 10%;
        margin-right: 10%;
    }

    .chart-container{
        width: 50%;
    }
}

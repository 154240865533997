
.container {
    height: 100px;
    display: grid;
    grid-template-columns: .8fr 1.6fr;
    max-width: 100%;
    width: 100%;
    position: absolute;
    bottom: 15px;
    justify-content: center;
    overflow-x: hidden;
}

.l {
    background: rgb(255, 141, 170);
    border-radius: 50%;
    width: 100px;
    left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    position: fixed;
}

.r {
    background: rgb(32, 7, 255);
    border-radius: 50px;
    right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    position: fixed;
}

.bottomnav-btn {
    text-decoration: none;
    border-radius: 50px;
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 70px;
    line-height: 70px;
    padding: 5px;
}

a.navbtn:hover {
    font-size: 1.6em;
}

.l:hover {
    background-color: rgb(219, 41, 255);
}

.r:hover {
    background-color: rgb(102, 186, 255);
}
* {
    margin: 0;
    padding: 0;
}

.the-form {
    padding: 20px;
}

.the-form label{
    padding: 10px;
}

.field {
    border-color: var(--primary-color);
    border-width: .1em;
}

span {
    font-weight: bold;
}

.filebox{
    background-color: white;
}

/* Bottom links */
.links{
    max-width: 100%;
    padding-top: 60px;
}

.btn {
    margin-top: 25px;
    width: 75%;
    text-decoration: none;
    border-radius: 50px;
    background-color: white;
    color: var(--primary-color);
    font-size: 1.5em;
    font-weight: bolder;
    cursor: pointer;
    height: 80px;
    display: inline;
    border-style: none;
}

.the-form input {
    height: 70px;
    border-radius: 10px;
}

.up-top {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}

.up-top-text {
    color: white;
    font-size: 3em;
    letter-spacing: -5px;
    padding-right: 10px;
    font-weight: bolder;
}

.login-text {
    color: white;
}


.login-logo {
    margin: auto;
    pointer-events: all;
}
  
.login-logo-div {
    display: flex;
}

  
/* Styles for landscape screen */
@media screen and (orientation:landscape){
    .big-logo {
        max-width: 50%;
        margin: auto;
    }
  
    label {
      max-width: 40%;
    }
  
    .title {
      font-size: 6em;
    }
  
    .btn {
      width: 40%;
    }

    .links{
        padding-top: 10px;
    }
  }
.main-content{
    width: 100%;
}

.grid-labels {
    display: grid;
    grid-template-columns: 10% 40% 17% 16% 17%;
    grid-auto-rows: 50px;
    border-radius: 10px;
    grid-row-gap: 1px;
    color: white;
    font-size: .85em;
}

.grid-container {
    display: grid;
    grid-template-columns: 10% 40% 17% 16% 17%;
    grid-auto-rows: 50px;
    background-color: white;
    border-radius: 10px;
    grid-row-gap: 1px;
}

.starter{
    background-color: rgb(61, 190, 201);
    position: relative;
    border-radius: 10px 10px 0 0;
}

.other-label{
    position: relative;
}

.grid-label{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 50px;
}

.grid-item{
    border-bottom: 1px solid #bbbbbb;
    height: 50px;
    position: relative;
}

.last-grid-item{
    height: 50px;
    position: relative;
}

.infinity{
    height: 25%;
}

.grid-item img{
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.last-grid-item img{
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.arrow {
    filter: invert(52%) sepia(9%) saturate(34%) hue-rotate(318deg) brightness(87%) contrast(89%);
    height: 70%;
}

.notIncluded {
    filter: invert(52%) sepia(9%) saturate(34%) hue-rotate(318deg) brightness(87%) contrast(89%);
    height: 30%;
}

.included {
    filter: invert(63%) sepia(69%) saturate(2945%) hue-rotate(87deg) brightness(91%) contrast(95%);
    height: 90%;
    width: 70%;
}

.grid-item-1{
    
}

.grid-item-2{
    background-color: white;
    text-align: left;
}

.chart-label{
    font-size: .8em;
    text-align: left;
    line-height: 50px;
    font-weight: 450;
}

.chart-text{
    font-size: .8em;
    text-align: center;
    line-height: 50px;
    font-size: larger;
    font-weight: 500;
}

.grid-item-4{
    background-color: rgb(227, 251, 252);
}

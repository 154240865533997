.names{
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    max-width: 95%;
}

.name {
    padding: 10px;
    padding-bottom: 0px;
}

.title{
    font-size: 1.3em;
    padding: 0px;
}

p {
    color: gray;
    font-size: .7em;
    text-align: left;
}

.signuplogosvg {
    padding-top: 10px;
    margin: auto;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%); 
    width: 250px;  
}

.terms {
    color: gray;
    padding-top: 10px;
    font-size: .5em;
}

.last-link {
    margin: 20px 0px;
}

.signupgradient {
    height: 100vh;
    background-image: linear-gradient(rgb(0, 0, 0), rgb(197, 10, 10));
}

.signupgradient label{
    padding: 5px;
}

.main-body p{
    color: white;
}
:root {
    --bg1: rgb(49, 42, 94);
    --nav1: rgb(209, 97, 59);
    --accent1: rgb(246, 237, 146);

    --nav2: rgb(219, 223, 92);
    --bg2: rgb(66, 128, 117);
    --accent2: rgb(129, 35, 32);

    --bg3: rgb(231, 189, 69);
    --nav3: rgb(48, 107, 61);
    --accent3: rgb(0, 0, 0);

    --bg4: rgb(133, 26, 18);
    --nav4: rgb(246, 237, 146);
    --accent4: rgb(49, 42, 94);
    --box-color: rgb(154, 59, 55);
}

/* PAGE 1 */
/* PAGE 1 */
/* PAGE 1 */
/* PAGE 1 */

.page1 {
    padding-top: 120px;
    background-color: var(--bg1);
    padding-bottom: 200px;
}

.page1 p {
    color: var(--nav1);
}

.page1-title-text {
    color: var(--nav1);
    font-size: 2em;
    font-weight: 800;
    text-align: left;
}

.page1-title {
    padding-top: 25px;
    padding-bottom: 25px;
}

.page1-description {
    text-align: left;
    font-size: 1.3em;
}

.page1-name-box {
    border-radius: 10px;
    height: 60px;
}

.page1-label {
    padding-top: 50px;
    margin-left: 35px;
    margin-bottom: 0px;
}

.page1-cta{
    margin-top: 15px;
    width: 300px;
    text-decoration: none;
    border-radius: 50px;
    background-color: var(--accent1);
    color: var(--bg1);
    font-size: 1.5em;
    font-weight: bolder;
    cursor: pointer;
    height: 80px;
    display: inline;
    border-style: none;
}

.page1-form {
    width: 80%;
}

/* PAGE 2 */
/* PAGE 2 */
/* PAGE 2 */
/* PAGE 2 */

.page2 {
    background-color: var(--bg2);
    padding-bottom: 100px;
}

.page2 p {
    color: var(--nav2);
}

.page2-title-text {
    color: var(--accent2);
    font-size: 2em;
    font-weight: 800;
    text-align: left;
}

.page2-title {
    padding-top: 50px;
}

.page2-description {
    padding-top: 25px;
    text-align: left;
    font-size: 1.3em;
}

.page2-cta{
    text-decoration: none;
    border-radius: 50px;
    background-color: var(--accent2);
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    margin-top: 150px;
    width: 300px;
    margin-bottom: 200px;
    border-style: none;
}

/* PAGE 3 */
/* PAGE 3 */
/* PAGE 3 */
/* PAGE 3 */

.page3 {
    background-color: var(--bg3);
    padding-bottom: 100px;
}

.page3 p {
    color: var(--nav3);
}

.page3-title-text {
    font-size: 2em;
    font-weight: 800;
    text-align: left;
}

.page3-title {
    padding-top: 50px;
}

.page3-description {
    padding-top: 25px;
    text-align: left;
    font-size: 1em;
}

.page3-cta{
    text-decoration: none;
    border-radius: 50px;
    background-color: var(--accent3);
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    margin-top: 50px;
    width: 300px;
    border-style: none;
}

/* PAGE 4 */
/* PAGE 4 */
/* PAGE 4 */
/* PAGE 4 */

.page4 {
    background-color: var(--bg4);
    padding-bottom: 100px;
}

.page4-title-text {
    font-size: 2em;
    font-weight: 800;
    text-align: left;
    color: var(--nav4)
}

.page4-title {
    padding-top: 50px;
    margin-bottom: 50px;
}

.page4-box-title {
    color: var(--nav4);
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.5em;
}

.page4-cta{
    text-decoration: none;
    border-radius: 50px;
    background-color: var(--accent4);
    color: white;
    font-size: 1.5em;
    cursor: pointer;
    height: 80px;
    padding: 10px;
    display: inline;
    margin-top: 50px;
    width: 300px;
    border-style: none;
}

.page4-box {
    background-color: var(--box-color);
    margin: auto;
    width: 90%;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: left;
    cursor: pointer;
    border-style: none;
    padding: 10px;
    border-radius: 25px;
}

.page4-box-info{
    color: white;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 1.3em;
    animation: .4s slideInFromTop;
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-30%);
        opacity: 0%;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.page4 span {
    margin-left: 22px;
}

.page4-box img {
    z-index: 100;
    position: absolute;
    right: 50px;
    margin-top: -40px;
    z-index: 100;
}

.upside-down {
    transform: rotate(180deg);
}

/* Styles for landscape screen */
@media screen and (orientation:landscape){
    .page4-box img {
        right: 120px;
    }

    .page1-form {
        padding-left: 5%;
    }

    .page1-content, .page2-content, .page3-content, .page4-content{
        width: 40%;
        margin: auto;
    }

    .page4-box img {
        right: 34%;
    }

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* variables */
:root {
  --heading-color: rgb(0, 18, 180);
  --text-color: #999;
  --primary-color: #d31f1f;
  --highlight-color: #d13267;
}

/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
/********************* GENERAL **********************/
body {
  font-family: Poppins, sans-serif;
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

ul {
  list-style-type: none;
}

.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 15px;
  border-width: 3px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  margin: 2px;
}

.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

h1{
  border: var(--primary-color);
}

.big-logo {
  max-width: 90%;
  margin: auto;
}

.big-logo-div {
  padding-top: 50px;
  display: grid;
}

.backgroundgradient {
  height: 100vh;
  background-image: linear-gradient(rgb(0, 0, 0), rgb(197, 10, 10));
}

.title {
  margin: 0;
  font-size: 3em;
  color: white;
  letter-spacing: 3px;
  text-shadow: -1px 1px 0px var(--primary-color),
                1px 1px 0 var(--primary-color),
                1px -1px 0 var(--primary-color),
                -1px -1px 0 var(--primary-color);
}

/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
/********************* FORMS **********************/
label {
  display: block;
  margin: auto;
  max-width: 90%;
}
label span {
  display: block;
  color: white;
  margin-bottom: 6px;
  text-align: left;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
}

textarea {
  min-height: 160px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin-top: 20px;
}

.message {
  color: green;
  background: greenyellow;
  border: 1px solid green;
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  margin: 20px auto;
}

.clickableLink{
  text-decoration: underline;
}

.clickableLink:hover {
  color: gray;
}

a {
  color: white;
}

a:visited {
  color: lightcoral;
}

.logosvg {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(28deg) brightness(108%) contrast(101%);
  width: 160px;
  pointer-events: none;
}

/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
/********************* Styles for landscape screen **********************/
@media screen and (orientation:landscape){
  .big-logo {
      max-width: 50%;
      margin: auto;
  }

  label {
    max-width: 30%;
  }

  .title {
    font-size: 6em;
  }

  .btn {
    width: 40%;
  }
}
.card {
    border-radius: 10px;
    margin-top: 15px;
    padding-bottom: 15px;
}

.card-header {
    background-color: var(--pricing-nav);
    color: white;
    height: 150px;
    border-radius: 20px;
}

.card-header-section{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.card-header-section p{
    color: white;
}

.card-header-section span{
    font-weight: normal;
}

.card-header-section p{
    text-align: left;
    margin-left: 20px;
    font-size: 1.5em;
    margin-top: 10px;
}

.card-content{
    background-color: white;
    border-radius: 0px 0px 20px 20px;
    margin-top: 15px;
}

.card-content p{
    color: black;
}

.description {
    width: 85%;
    margin: auto;
    margin-top: -70px;
    color: black;
    font-size: 1em;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
}

.label {
    background-color: var(--pricing-accent);
    position: absolute;
    padding: 5px;
    border-radius: 10px;
    color: white;
    width: 150px;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
    margin-top: -25px;
}

.get {
    height: 60px;
    border-radius: 50px;
    background-color: white;
    border-color: var(--pricing-nav);
    color: var(--pricing-nav);
    align-items: center;
    font-size: 1.3em;
    margin-bottom: 10px;
    width: 70%;
    cursor: pointer;
}

h2 {
    color: white;
    text-align: left;
    margin-left: 7%;
}

.get a{
    color: var(--pricing-nav);
    text-decoration: none;
    font-weight: bold;
}


/* Styles for landscape screen */
@media screen and (orientation:landscape){

}